import { isDesktopSelector } from './selectors/sizeSelectors';

export function scrollToSection(id, smooth?: boolean) {
  // step 1: check target element and get its position
  const targetElement = document.getElementById(id);
  if (!targetElement) {
    // don't scroll if the target element was not found
    return;
  }

  if (smooth) {
    targetElement.scrollIntoView({ behavior: 'smooth' });
  } else scrollYToElement(targetElement);
}

export function scrollYToElement(targetElement) {
  const targetElementPosition = targetElement.offsetTop;

  // step 2: check scroll offset depending on scroll direction and header height
  const header = document.getElementById('page-header');
  let headerHeight = 0;
  // frameless page or not
  if (header) {
    const isDesktop = isDesktopSelector({ width: window.innerWidth });
    // mobile header or not
    if (isDesktop) {
      const willScrollDown = document.documentElement.scrollTop < targetElementPosition;
      headerHeight = willScrollDown ? 69 : 138;
    } else {
      headerHeight = 60;
    }
  }

  // step 3: actual scrolling (windows edge needs body to scroll)
  scrollToPosition(0, targetElementPosition - headerHeight - 10);
}

export function scrollToPosition(x, y) {
  const scrollDoc =
    navigator.userAgent.indexOf('Edge') >= 0 ? document.body : document.documentElement;
  scrollDoc.scrollTo(x, y);
  document.addEventListener(
    'scrollend',
    () => {
      // not scrolled up by y, because mega menu header logic (scrollHandler) intervenes. Therefore we scroll up by y again.
      if (scrollDoc.scrollTop !== y) {
        scrollDoc.scrollTo(x, y);
      }
    },
    { once: true },
  );
}

// this function works like scrollToPosition but checks whether the user is really scrolled
// to the given position. Might not be the case when the page hasn't finished loading
export async function ensureScrollYToPosition({ y, retryInterval = 20, timeout = 100 }) {
  return new Promise((resolve) => {
    const checkInterval = setInterval(() => {
      window.scrollTo(0, y);
      if (window.scrollY === parseInt(y, 10)) {
        resolve(true);
      }
    }, retryInterval);

    setTimeout(() => {
      clearInterval(checkInterval);
      resolve(false);
    }, timeout);
  });
}
