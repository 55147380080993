import { useFormikContext } from 'formik';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import * as locales from 'date-fns/locale';
import isEmpty from 'lodash.isempty';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { DateOrTimePickerField } from '../date-or-time-picker';

// register all locales
Object.keys(locales).forEach((locItem) => {
  registerLocale(locItem, locales[locItem]);
});

interface DatePickerProps {
  field: DateOrTimePickerField;
  onChange: (date: Date | null) => void;
  className: string;
  selectedTime: Date | null;
  language: string;
}

export default function DatePicker({
  field: { name, rules, comment },
  onChange,
  className,
  language,
  selectedTime,
}: Readonly<DatePickerProps>) {
  const { values, errors, touched, setFieldTouched } = useFormikContext<Record<string, string>>();
  const uppercaseLang = language ? language.substring(0, 2) : '';
  const lang = uppercaseLang?.toLowerCase();
  const dateformat = useDateFormat();

  return (
    <div className={classNameBuilder(className, values[name] && 'form-item--filled')}>
      {/* prevent events so the datepicker closes when selecting a date */}
      <label htmlFor={name} onClick={(e) => e.preventDefault()} onKeyUp={(e) => e.preventDefault()}>
        <ReactDatePicker
          locale={lang}
          title={comment}
          id={name}
          value={values[name]}
          name={name}
          selected={selectedTime}
          dateFormat={dateformat}
          onChange={onChange}
          onBlur={() => setFieldTouched(name, true)}
          autoComplete="off"
        />
        {dateformat && <InnerHtml as="span" content={dateformat} />}
      </label>
      <InnerHtml
        as="p"
        className="form-item__error-message"
        content={
          (errors[name] &&
            touched[name] &&
            !isEmpty(rules) &&
            errors[name] !== 'keepErrorMessage' &&
            errors[name]) ||
          ''
        }
      />
    </div>
  );
}
