import { FIELD_MAPPING } from '../../utils/mappings';

export function ConsentField({ field, customType, hasValidPrecondition }) {
  return (
    <>
      {field.consentInformation.map((consentPart) => {
        const FieldElement = FIELD_MAPPING[consentPart.fieldType || consentPart.type];
        return (
          <div key={consentPart.id}>
            <FieldElement
              // coding guideline exception: we pass down generic props since there are too many different fields
              field={consentPart}
              hasValidPrecondition={consentPart.hasValidPrecondition}
            />
          </div>
        );
      })}
    </>
  );
}
