import { forwardRef } from 'react';
import isEmpty from 'lodash.isempty';
import key from 'weak-key';
import { Button as GDDSButton } from '@geberit/gdds';

// styles
import styles from './file-upload-nordics.module.scss';

// types
import type { FormikProps } from 'formik';

// components
import UploadedFileInfo from './uploaded-file-info';
import { InnerHtml } from 'components/InnerHtml/inner-html';

interface FileUploadNordicsProps {
  label: string;
  comment: string;
  name: string;
  type: string;
  handleOnChange: (e?: React.ChangeEvent<HTMLInputElement>, maxFileCount?: number) => void;
  maxFileCount: number;
  placeholderString: string;
  deleteFile: (name: string, errorMessage: string, index: number) => void;
  rules: Rule[];
  errorMessage: string;
  files?: any;
  customType?: string;
  className?: string;
}

function FileUploadNordics(
  {
    label,
    className,
    comment,
    name,
    type,
    customType,
    handleOnChange,
    maxFileCount,
    placeholderString,
    deleteFile,
    files,
    rules,
    errorMessage,
    values,
  }: FileUploadNordicsProps & Pick<FormikProps<any>, 'values'>,
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
) {
  const isRequired = !isEmpty(rules);
  const hideFileUpload =
    !isEmpty(files) && !isEmpty(files[name]) && files[name].length === maxFileCount;

  return (
    <div className={className}>
      <span className={styles.label}>{label}</span>

      <input
        ref={inputRef}
        title={comment}
        id={name}
        name={name}
        {...(type === 'number'
          ? { inputMode: 'numeric', pattern: '[0-9]*', type: 'text' }
          : { type: (customType ?? '') || type })}
        onChange={(e) => {
          handleOnChange(e, maxFileCount);
        }}
      />
      <label htmlFor={name} className={styles.uploadbutton}>
        {!isEmpty(placeholderString) && <InnerHtml as="span" content={placeholderString} />}
        {hideFileUpload || (
          <GDDSButton
            stylingType="secondary"
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            {label}
          </GDDSButton>
        )}
      </label>

      {!isEmpty(files) && files[name] && (
        <div className={styles.nordUploadFiles}>
          {files[name].map((file, index) => (
            <UploadedFileInfo
              key={key(file)}
              fileName={file.name}
              deleteFile={() => {
                deleteFile(name, isRequired ? rules[0].errorMessage ?? '' : '', index);
                values[name] = [];
              }}
            />
          ))}
        </div>
      )}
      <InnerHtml as="p" className={styles.nordUploadError} content={errorMessage} />
    </div>
  );
}

export default forwardRef(FileUploadNordics);
