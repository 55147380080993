// styles
import styles from './form.scss';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

export function getPreviewId(formType: string, index?: number) {
  return formType === 'leadmodule' || index === undefined ? undefined : `#${index}`;
}

export function getClassName({
  isGroup,
  fieldSize,
  hasValidPrecondition,
  hasOxomitButtonWrapper,
  isFileUpload,
  isNord,
  reduceBottomDistanceToNextElement,
}) {
  return classNameBuilder(
    isGroup ? styles.gddsField : `cell medium-${fieldSize}`,
    fieldSize === 6 ? 'half' : 'full',
    hasValidPrecondition,
    hasOxomitButtonWrapper,
    isFileUpload && 'fileUpload',
    isNord && reduceBottomDistanceToNextElement && 'consent-form-checkbox',
  );
}
